import React from 'react';

import { BlogType, TenantGlobalBlogPageData } from '@ha/contentful';

import { BLOG_TENANT } from 'ha/constants/pageNames';

import { useIntl } from 'ha/i18n';

import {
  Page,
  FeaturedArticles,
  Articles,
  TopicClusters,
  FeaturedLocations,
} from 'ha/modules/Blog/components';
import { useTopicClusters } from 'ha/modules/Blog/hooks';
import { useRequiredHermes } from 'ha/modules/Hermes';

const Global: React.FC = () => {
  const { T } = useIntl();
  const {
    data: {
      countries,
      trendingArticles,
      articles,
      topicClusters,
      featuredCountries,
    },
  } = useRequiredHermes<TenantGlobalBlogPageData>();

  const { topics, filteredArticles, isSelectedAll, handleSelectionAll } =
    useTopicClusters({
      topicClusters,
      articles,
    });

  return (
    <Page
      page={{
        name: BLOG_TENANT,
        category: BlogType.TENANT,
      }}
      hero={{
        title: T('blog.tenant_main_page.title'),
        dropdown: {
          countries,
          title: countries[0]?.name,
        },
      }}
      breadcrumbs={{ blogType: BlogType.TENANT }}
    >
      <TopicClusters
        isSelectedAll={isSelectedAll}
        handleSelectionAll={handleSelectionAll}
        topicClusters={topics}
      />
      {isSelectedAll && <FeaturedArticles articles={trendingArticles} />}
      <Articles
        title={T('blog.main_page.section_all_articles')}
        articles={filteredArticles}
      />
      <FeaturedLocations
        title={T('blog.main_page.section_countries_articles')}
        subTitle={T('blog.label_explore')}
        columns={4}
        locations={featuredCountries}
      />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default Global;
